// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'http://wos.gardendesignlandscaping.com:8000',
  paypalSecret: 'AdOEwASN-CkaTet0UByE8QfD8UYAOj52d8rEhPbd6h4H3nBDTDIJGVRJ1Ku2ASdA-hnsjg9t8eY1uNz4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

