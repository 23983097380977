<!-- ======= Footer ======= -->
<footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 p-0 col-md-6 footer-logo">
            <img src="assets/media/logo-white.png">
          </div>
          <div class="col-lg-8 p-0 col-md-6 footer-links text-right">
            <ul>
              <li><a routerLink="/about-us">about us</a></li>
              <li><a routerLink="/reviews">reviews</a></li>
              <li><a routerLink="/contact-us">contact us</a></li>
              <li><a routerLink="/blog">blog</a></li>
              <li><a routerLink="/privacy-policy">privacy policy</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    
    <div class="footer-bottom">
      <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-6 footer-links">
                <ul>
                    <li><a routerLink="/">GET PAY STUBS</a></li>
                    <li><a routerLink="/create-stub">Create Pay Stub Now</a></li>
                    <li><a routerLink="/stub-samples">Stub Samples</a></li>
                    <li><a routerLink="/stub-faq">FAQ</a></li>
                </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
                <ul>
                    <li><a routerLink="/">W2 Form</a></li>
                    <li><a routerLink="/w2-form">Create W2 Form Now</a></li>
                    <!--<li><a routerLink="/w2-samples">W2 Samples</a></li>-->
                    <li><a routerLink="/w2-faq">FAQ</a></li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-6 footer-payment-logos">
                <div class="payment-logos float-md-right">
                    <img src="assets/media/payment-methods.png">
                </div>
            </div>

        </div>
      </div>
    </div>

    <div class="container">
        <div class="copyright">
            © Copyright 2020 GetPayStubs.net. All Rights Reserved.
        </div>
    </div>

</footer>
<!-- End Footer -->