<main id="main" class="inner-page">

    <!-- ======= Privacy Policy title Section ======= -->
    <section id="" class="common-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 inner-page-title">
            <h2>Privacy Policy</h2>
          </div>
        </div>
      </div>
    </section><!-- End Privacy Policy title Section -->
  
    <!-- ======= privacy-policy Section ======= -->
    <section id="privacy-policy" class="privacy-policy content-box">
      <div class="container" data-aos="fade-up">
        <div class="row pt-5 pb-5">
          <div class="col-lg-10 offset-lg-1">
            <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
            </p>
            <h5 class="mb-4 mt-4 font-weight-bolder">What personal information do we collect from the people that visit our blog, website or app?</h5>
            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, credit card information, social security number or other details to help you with your experience.</p>
            

            <h5 class="mb-4 mt-4 font-weight-bolder">When do we collect information?</h5>
            <p>We collect information from you when you place an order, fill out a form or enter information on our site.</p>
            


          </div>
          <div class="col-lg-10 offset-lg-1 ">
            <hr class="content-separator">
          </div>
          <div class="col-lg-10 offset-lg-1 ">
            <a routerlink="/create-stub" class="primary-btn"> Create Your Pay Stub Now </a>
          </div>
        </div>
  
      </div>
    </section><!-- End privacy-policy Section -->
  
  </main><!-- End #main -->
  