<main id="main" class="inner-page">

    <!-- ======= Stub templates title Section ======= -->
    <section id="" class="common-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 inner-page-title">
            <h2>Modern W2 Form Sample Templates</h2>
          </div>
        </div>
      </div>
    </section><!-- End Stub templates title Section -->
  
    <!-- ======= stub templates Section ======= -->
    <section id="about-us" class="about-us content-box">
      <div class="container" data-aos="fade-up">
        <div class="row pt-5">
          <div class="col-lg-10 mb-5 offset-lg-1">
            <h4 class="mb-3">Check our variety of templates and select the one that answers your needs</h4>
            <p>Our paycheck W2 Form maker offers a diversity of samples, tailored to fulfill your demands. All samples are
              modern, practical and professional, adapted and trusted by thousands of users.</p>
          </div>
          <div class="col-lg-8 offset-lg-2">
            <div class="col-lg-6 mb-4 float-left pl-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('a')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-A.jpg" alt="Horizontal Blue Pay Stub Sample Template"
                      class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-lg-6 mb-4 float-left pr-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('b')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-B.jpg" alt="Horizontal Blue Pay Stub Sample Template" class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
  
            <div class="col-lg-6 mb-4 float-left pl-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('c')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-C.jpg" alt="Horizontal Blue Pay Stub Sample Template" class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-lg-6 mb-4 float-left pr-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('d')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-D.jpg" alt="Horizontal Blue Pay Stub Sample Template" class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-lg-6 mb-4 float-left pl-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('e')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-C.jpg" alt="Horizontal Blue Pay Stub Sample Template" class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-lg-6 mb-4 float-left pr-lg-0">
              <div class="card">
                <div class="sample-template">
                  <div class="sample-info">
                    <p>Template Name</p>
                    <a routerLink="/w2-form" (click)="changeTemplate('f')">Use this template</a>
                  </div>
                  <div class="sample-img p-4">
                    <img src="/assets/media/template-D.jpg" alt="Horizontal Blue Pay Stub Sample Template" class="w-100 img-responsive">
                  </div>
                </div>
              </div>
            </div>
  
          
  
          </div>
        </div>
  
        <div class="row pb-5">
          <div class="col-lg-10 offset-lg-1 ">
            <hr class="content-separator">
          </div>
          <div class="col-lg-10 text-center offset-lg-1 ">
            <a routerLink="/w2-form" class="primary-btn">Create Your W2 Form Now </a>
          </div>
        </div>
  
      </div>
    </section><!-- End stub templates Section -->
  
  </main><!-- End #main -->