<main id="main" class="inner-page">

  <!-- ======= Contact Us title Section ======= -->
  <section id="" class="common-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 inner-page-title">
          <h2>Contact Us</h2>
        </div>
      </div>
    </div>
  </section><!-- End Contact Us title Section -->

  <!-- ======= Contact Us Section ======= -->
  <section id="contact-us" class="contact-us content-box">
    <div class="container" data-aos="fade-up">
      <div class="row pt-5">
        <div class="col-lg-10 offset-lg-1">
          <div class="col-lg-6 pl-lg-0 float-left">
            <h4 class="mb-5 mt-4">Get In Touch</h4>
            <div>
              <!-- <form action="" method=""> -->
              <div class="row">
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group validate-me">
                    <label for="Name">Name<span class="mandatory">*</span></label>
                    <input type="text" class="form-control" id="Name" placeholder="This field is mandatory" required>
                    <!-- <div class="invalid-feedback">Name is required</div> -->
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group validate-me">
                    <label for="Email">Email<span class="mandatory">*</span></label>
                    <input type="email" class="form-control" id="Email" placeholder="This field is mandatory" required>
                    <!-- <div class="invalid-feedback">Email is required</div> -->
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group validate-me">
                    <label for="Service">Service<span class="mandatory">*</span></label>
                    <select id="Service" class="form-control custom-select">
                      <option>PayStub Form</option>
                      <option>W2 Form</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group">
                    <label for="Order-Number">Order Number (Optional)</label>
                    <input type="text" class="form-control" id="Order-Numbe">
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group validate-me">
                    <label for="Message">Message<span class="mandatory">*</span></label>
                    <textarea id="Message"></textarea>

                  </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-2">
                  <div class="form-group text-right">
                    <button type="submit" class="primary-btn">Submit</button>
                  </div>
                </div>

              </div>
              <!-- </form> -->
            </div>

          </div>
          <div class="col-lg-6 pr-lg-0 float-left">
            <h4 class="mb-5 mt-4">Need some live help?</h4>
            <img class="mb-4 w-100" src="assets/media/support.webp">
            <p class="mb-4">We offer a 24/7 Toll Free Customer Support line to all our customers, just give us a quick
              call, and we’d
              be more than happy to answer any questions or concerns you may have.</p>
            <div class="call-now">
              <p>Call Now! +1 (855) 313-1811</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row pb-5">
        <div class="col-lg-10 offset-lg-1 ">
          <hr class="content-separator">
        </div>
        <div class="col-lg-10 offset-lg-1 ">
          <p class="mb-3">If you are here because you have not received your order, then please click the button below
            to resend your order before trying to contact us.</p>
          <a routerLink="/order-resend" class="secondary-btn">Resend Your Order</a>
        </div>
      </div>
    </div>

  </section><!-- End Contact Us Section -->

</main><!-- End #main -->
