<!-- ======= Header ======= -->
<div class="overlay"></div>
<header id="header" class="fixed-top">

    <div class="header-topbar">
        <div class="container d-flex p-0">
            <div class="col-md-8 p-0">
                <p><b>NEED SOME HELP?</b> <span class="hide-mobile"> Please call our Heroic Customer Support 24/7</span></p>
            </div>
            <div class="col-md-4 p-0">
                <p class="topbar-phone">+1-888-999-0000</p>
            </div>
        </div>
    </div>

    <div class="container d-flex main-navigation">

        <div class="logo mr-auto">
            <h1 class="text-light"><a href="/"><img src="assets/media/logo.png"></a></h1>
        </div>

        <app-nav></app-nav>

    </div>

</header>
<!-- End Header -->