<main id="main" class="inner-page">

  <!-- ======= About Us title Section ======= -->
  <section id="" class="common-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 inner-page-title">
          <h2>About Us</h2>
        </div>
      </div>
    </div>
  </section><!-- End About Us title Section -->

  <!-- ======= about-us Section ======= -->
  <section id="about-us" class="about-us content-box">
    <div class="container" data-aos="fade-up">
      <div class="row pt-5 pb-5">
        <div class="col-lg-10 offset-lg-1">
          <h5 class="mb-3"> Who we are</h5>
          <p>PaystubCreator.net was built by an American professional accounting firm in order to offer to its clients
            the ability to create their own paycheck stubs.
          </p>
          <h5 class="mb-3 mt-5"> Our services</h5>
          <p> Our stub generation tool is used to provide proof of income or to provide a paystub to employees by small
            business owners.</p>
          <p> Our calculations are validated by our professional accountants. Ever since the website was launched to the
            public, we have had a massive amount of satisfied users that use the service.</p>
          <p>If you have any further questions, do not hesitate to contact us.</p>
        </div>
        <div class="col-lg-10 offset-lg-1 ">
          <hr class="content-separator">
        </div>
        <div class="col-lg-10 offset-lg-1 ">
          <a routerlink="/create-stub" class="primary-btn"> Create Your Pay Stub Now </a>
        </div>
      </div>

    </div>
  </section><!-- End about-us Section -->

</main><!-- End #main -->
