<main id="main" class="createstub">
  <div class="container">
    <!-- ======= Testimonials Section ======= -->
    <section id="create-your-paystub" class="common-block">
      <div class="#">
        <div class="row">
          <div class="col-lg-12 createstub-title">
            <h2>Reviews</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="reviews" class="review-overview-block">
      <div class=" pr-5 pl-5">
        <div class="page-content review-overview">
          <div class="review-overview-stars">
            <div class="reviews-overview__total">4.0 <span><i class="fa fa-star"></i></span></div>
            <div class="reviews-overview__total-info">
              <div class="reviews-overview__total-desc">4.0 out of 5 stars</div>
              <div class="reviews-overview__total-reviews">495 reviews</div>
            </div>
          </div>
          <div class="review-overview-statistics">
            <div class="reviews-overview__chart reviews-chart">
              <div class="reviews-chart__line">
                <div class="reviews-chart__text">* stars</div>
                <div class="reviews-chart__progress-bar">
                  <div class="reviews-chart__progress" data-progress="71" style="transform: translateX(-29%);"></div>
                </div>
              </div>
              <div class="reviews-chart__line">
                <div class="reviews-chart__text">* stars</div>
                <div class="reviews-chart__progress-bar">
                  <div class="reviews-chart__progress" data-progress="71" style="transform: translateX(-49%);"></div>
                </div>
              </div>
              <div class="reviews-chart__line">
                <div class="reviews-chart__text">* stars</div>
                <div class="reviews-chart__progress-bar">
                  <div class="reviews-chart__progress" data-progress="71" style="transform: translateX(-19%);"></div>
                </div>
              </div>
              <div class="reviews-chart__line">
                <div class="reviews-chart__text">* stars</div>
                <div class="reviews-chart__progress-bar">
                  <div class="reviews-chart__progress" data-progress="71" style="transform: translateX(-9%);"></div>
                </div>
              </div>
              <div class="reviews-chart__line">
                <div class="reviews-chart__text">* stars</div>
                <div class="reviews-chart__progress-bar">
                  <div class="reviews-chart__progress" data-progress="71" style="transform: translateX(-79%);"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="reviews" class="review-prompt-block">
      <div class=" pr-5 pl-5">
        <div class="page-content reviews-prompt">
          <div>
            <span>Tell us what you think</span>
            <div class="star-rating">
              <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                class="fa fa-star"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="reviews" class="review-list-block">
      <div class=" pr-5 pl-5">
        <div class="reviews-list">
          <div class="reviews-list__column">
            <div class="reviews-list__entry">
              <div class="reviews-list__name">User Name</div>
              <div class="reviews-list__stars">
                <div class="star-rating star-5">
                  <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                    class="fa fa-star"></i><i class="fa fa-star"></i>
                </div>
              </div>
              <div class="reviews-list__date">April 24th, 2020</div>
              <div class="reviews-list__text">Excellent work. Thank you</div>
            </div>
            <div class="reviews-list__entry">
              <div class="reviews-list__name">User Name</div>
              <div class="reviews-list__stars">
                <div class="star-rating star-5">
                  <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                    class="fa fa-star"></i><i class="fa fa-star"></i>
                </div>
              </div>
              <div class="reviews-list__date">April 24th, 2020</div>
              <div class="reviews-list__verified">
                <i class="fa fa-check"></i> Verified User
              </div>
              <div class="reviews-list__product reviews-list__product-blue">
                <span>Paystub</span>
              </div>
              <div class="reviews-list__text">Pretty easy compared to another one I just wasted money on. Would like
                more finite control over some things, but overall very good experience. Pretty easy compared to another
                one I just wasted money on.</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- End Create your pay stub Section -->
  </div>
</main><!-- End #main -->
