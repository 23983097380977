<main id="main" class="inner-page">

    <!-- ======= Form W-2 title Section ======= -->
    <section id="" class="common-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 inner-page-title">
            <h2>Form W-2 Creator FAQ</h2>
          </div>
        </div>
      </div>
    </section><!-- End Form W-2 title Section -->
  
    <!-- ======= Form_W-2 Section ======= -->
    <section id="Form_W-2" class="Form_W-2 content-box">
      <div class="container" data-aos="fade-up">
        <div class="row pt-5">
          <div class="col-lg-10 offset-lg-1">
            <div id="faq" role="tablist" aria-multiselectable="true">

                <!-- Question 1 -->
                <div class="mb-4">
                  <div class="" role="tab" id="question-heading-01">
                    <div class="row">
                      <div class="col-12 no-padding faq-head">
                        <a data-toggle="collapse" data-parent="#faq" href="#question-body-01" aria-expanded="false" aria-controls="question-body-01"
                          class="collapsed ">
                          <i class="fa " aria-hidden="true"></i>
                          <h5>Who are we?</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div id="question-body-01" class="collapse " role="tabpanel" aria-labelledby="question-heading-01" aria-expanded="true">
                    <div class="mt-4">
                      <p>We are a group of local accountants, creating softwares that help small business owners and freelancers generate stubs and keep track of their finances. They don't need to buy tailor-made softwares to handle their limited payments. We handle their operations at very reasonable rates.</p>
                      <hr class="content-separator">
                    </div>
                  </div>
                </div>

                  <!-- Question 2 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-02">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-02" aria-expanded="false" aria-controls="question-body-02"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>Are the created W-2 forms legal documents?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-02" class="collapse " role="tabpanel" aria-labelledby="question-heading-02" aria-expanded="true">
                      <div class="mt-4">
                        <p>Every stub or form created using our tools is legal, taking into account that all the filled information is correct.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                   <!-- Question 3 -->
                   <div class="mb-4">
                    <div class="" role="tab" id="question-heading-03">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-03" aria-expanded="false" aria-controls="question-body-03"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>How will you get your Form W-2?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-03" class="collapse " role="tabpanel" aria-labelledby="question-heading-03" aria-expanded="true">
                      <div class="mt-4">
                        <p>You choose how to receive your form W-2. You can either have it as a zip file, consisting, once opened, of one PDF file per pay date, or in a single PDF file with one page per pay date. All the files you receive are compatible with any version of Adobe Acrobat Reader, and can be printed using any printer.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 4 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-04">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-04" aria-expanded="false" aria-controls="question-body-04"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>How quick is the Form W-2 sent to you?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-04" class="collapse " role="tabpanel" aria-labelledby="question-heading-04" aria-expanded="true">
                      <div class="mt-4">
                        <p>There is nothing such as waiting time with PayStubCreator. Once you submit the order, the documents are sent to you by e-mail. If you can’t find them in your inbox, just check your Junk-Mail or your Spam folder, you’ll see them there.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 5 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-05">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-05" aria-expanded="false" aria-controls="question-body-05"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>How can you fix a mistake if you already submitted your order?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-05" class="collapse " role="tabpanel" aria-labelledby="question-heading-05" aria-expanded="true">
                      <div class="mt-4">
                        <p>You don’t have to worry about that. You can just create another order and send us an e-mail asking for a refund and explaining the reason. We’ll then be able to check the corrected info, and gladly refund the withdrawn amount. Or you can contact us regarding the amendment you need to place, and wait for e-mail you the corrected documents.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 6 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-06">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-06" aria-expanded="false" aria-controls="question-body-06"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>How can you pay?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-06" class="collapse " role="tabpanel" aria-labelledby="question-heading-06" aria-expanded="true">
                      <div class="mt-4">
                        <p>You can either pay with your Paypal account, in case you’ve got one, or just choose to “Pay with an online bank account, debit or credit card”. You don’t need a Paypal account to use the second option, you just pay using any credit card (VISA, American Express, MasterCard or Discover).</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                    <!-- Question 7 -->
                    <div class="mb-4">
                        <div class="" role="tab" id="question-heading-07">
                          <div class="row">
                            <div class="col-12 no-padding faq-head">
                              <a data-toggle="collapse" data-parent="#faq" href="#question-body-07" aria-expanded="false" aria-controls="question-body-07"
                                class="collapsed ">
                                <i class="fa" aria-hidden="true"></i>
                                <h5>How do we guarantee the security of your information?</h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div id="question-body-07" class="collapse " role="tabpanel" aria-labelledby="question-heading-07" aria-expanded="true">
                          <div class="mt-4">
                            <p>All communication between your computer and our servers is encrypted using SSL/TLS (Let’s Encrypt SSL), to ensure that your information remains private and secure.</p>
                            <hr class="content-separator">  
                        </div>
                        </div>
                      </div>

                      <!-- Question 8 -->
                    <div class="mb-4">
                        <div class="" role="tab" id="question-heading-08">
                          <div class="row">
                            <div class="col-12 no-padding faq-head">
                              <a data-toggle="collapse" data-parent="#faq" href="#question-body-08" aria-expanded="false" aria-controls="question-body-08"
                                class="collapsed ">
                                <i class="fa" aria-hidden="true"></i>
                                <h5>How to reach us?</h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div id="question-body-08" class="collapse " role="tabpanel" aria-labelledby="question-heading-08" aria-expanded="true">
                          <div class="mt-4">
                            <p>You can contact us using the Contact Us link in the menu on the top right of the page. We usually reply within 48 hours.</p>
                        </div>
                        </div>
                      </div>
              
      
              </div>
          </div>
        </div>

        <div class="row pb-5">
          <div class="col-lg-10 offset-lg-1 ">
            <hr class="content-separator">
          </div>
          <div class="col-lg-10 offset-lg-1 ">
            <a routerlink="/create-stub" class="primary-btn"> Create Your Pay Stub Now </a>
          </div>
        </div>
  
      </div>
    </section><!-- End Form_W-2 Section -->
  
  </main><!-- End #main -->
  