<main id="main" class="inner-page">

    <!-- ======= Blog title Section ======= -->
    <section id="" class="common-block">
        <div class="container">
        <div class="row">
            <div class="col-lg-12 inner-page-title">
            <h2>Blog</h2>
            </div>
        </div>
        </div>
    </section><!-- End Blog title Section -->
    
    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog p-0 blog-content">
        <div class="container" data-aos="fade-up">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
            <div class="row">
                <div class="col-lg-6">
                    <div class="featured-blog-img">
                    <img src="assets/media/blog/feature-blog.jpg" />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="featured-blog-box">
                    <div class="featured-blog-title">
                        <a href="#">
                        The Tax Filing Deadline And Extensions To Keep In Mind
                        </a>
                    </div>
                    <div class="featured-blog-author">
                        <div class="author-img">
                        <a href="#"><img src="assets/media//blog-author/blog-author.jpg" alt=""></a>
                        </div>
                        <div class="author-name"><a href="#">Phil Baker</a></div>
                    </div>
                    <div class="featured-blog-content">
                        This March (2020) we saw something unprecedented happen when the President of the United States
                        delayed taxes. When the COVID-9 crisis began to ramp
                    </div>
                    <div class="featured-blog-more">
                        <a href="#">
                        Read more
                        </a></div>
                    </div>
                </div>
            </div>
            <div class="row search">
                <div class="col-lg-6">
                <div class="search-categories">
                    <span>All (Categories)</span><i class="fa fa-chevron-right" aria-hidden="true"></i>
                    <select class="search-category" name="search-category">
                    <option value="all">All (Categories)</option>
                    <option value="how-to">How To</option>
                    <option value="general-knowledge">General Knowledge</option>
                    </select>
                </div>
                </div>
                <div class="col-lg-6">
                <div class="search-input">
                    <div id="search-form" action="/blog/search" method="GET">
                    <input id="search" type="text" name="q" autocomplete="off">
                    <label for="search"><i class="fa fa-search" aria-hidden="true"></i></label>
                    </div>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 mb-5 col-md-6 d-flex align-items-stretch">
                <article class="blog-box">
                    <div class="blog-img">
                    <img src="assets/media/blog-01.jpg" alt="" class="img-fluid">
                    </div>
                    <h3 class="blog-title">The Most Prolific W2 Scams Explained - And How to Avoid Them</h3>
                    <div class="blog-content">
                    <p>Is your business vulnerable to W2 scams? Have you recently rece...</p>
                    <div class="read-more">
                        <a href="#">Read More</a>
                    </div>
                    </div>
                </article>
                </div>
                <div class="col-lg-4 mb-5 col-md-6 d-flex align-items-stretch">
                <article class="blog-box">
                    <div class="blog-img">
                    <img src="assets/media/blog-02.jpeg" alt="" class="img-fluid">
                    </div>
                    <h3 class="blog-title">The Most Prolific W2 Scams Explained - And How to Avoid Them</h3>
                    <div class="blog-content">
                    <p>Is your business vulnerable to W2 scams? Have you recently rece...</p>
                    <div class="read-more">
                        <a href="#">Read More</a>
                    </div>
                    </div>
                </article>
                </div>
                <div class="col-lg-4 mb-5 col-md-6 d-flex align-items-stretch">
                <article class="blog-box">
                    <div class="blog-img">
                    <img src="assets/media/blog-03.jpeg" alt="" class="img-fluid">
                    </div>
                    <h3 class="blog-title">The Most Prolific W2 Scams Explained - And How to Avoid Them</h3>
                    <div class="blog-content">
                    <p>Is your business vulnerable to W2 scams? Have you recently rece...</p>
                    <div class="read-more">
                        <a href="#">Read More</a>
                    </div>
                    </div>
                </article>
                </div>
                <div class="col-lg-4 mb-5 col-md-6 d-flex align-items-stretch">
                <article class="blog-box">
                    <div class="blog-img">
                    <img src="assets/media/blog-02.jpeg" alt="" class="img-fluid">
                    </div>
                    <h3 class="blog-title">The Most Prolific W2 Scams Explained - And How to Avoid Them</h3>
                    <div class="blog-content">
                    <p>Is your business vulnerable to W2 scams? Have you recently rece...</p>
                    <div class="read-more">
                        <a href="#">Read More</a>
                    </div>
                    </div>
                </article>
                </div>
                <div class="col-lg-4 mb-5 col-md-6 d-flex align-items-stretch">
                <article class="blog-box">
                    <div class="blog-img">
                    <img src="assets/media/blog-03.jpeg" alt="" class="img-fluid">
                    </div>
                    <h3 class="blog-title">The Most Prolific W2 Scams Explained - And How to Avoid Them</h3>
                    <div class="blog-content">
                    <p>Is your business vulnerable to W2 scams? Have you recently rece...</p>
                    <div class="read-more">
                        <a href="#">Read More</a>
                    </div>
                    </div>
                </article>
                </div>
            </div>
            </div>
        </div>
        </div>
    
    </section><!-- End Blog Section -->
    
</main><!-- End #main -->
      