<main id="main" class="inner-page">

    <!-- ======= Stub FAQ title Section ======= -->
    <section id="" class="common-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 inner-page-title">
            <h2>Stub FAQ</h2>
          </div>
        </div>
      </div>
    </section><!-- End Stub FAQ title Section -->
  
    <!-- ======= Stub-FAQ Section ======= -->
    <section id="Stub-FAQ" class="Stub-FAQ content-box">
      <div class="container" data-aos="fade-up">
        <div class="row pt-5">
          <div class="col-lg-10 offset-lg-1">
            <div id="faq" role="tablist" aria-multiselectable="true">

                <!-- Question 1 -->
                <div class="mb-4">
                  <div class="" role="tab" id="question-heading-01">
                    <div class="row">
                      <div class="col-12 no-padding faq-head">
                        <a data-toggle="collapse" data-parent="#faq" href="#question-body-01" aria-expanded="false" aria-controls="question-body-01"
                          class="collapsed ">
                          <i class="fa " aria-hidden="true"></i>
                          <h5>Who are we?</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div id="question-body-01" class="collapse " role="tabpanel" aria-labelledby="question-heading-01" aria-expanded="true">
                    <div class="mt-4">
                      <p>We are a group of local accountants, creating softwares that help small business owners and freelancers generate stubs and keep track of their finances. They don't need to buy tailor-made softwares to handle their limited payments. We handle their operations at very reasonable rates.</p>
                      <hr class="content-separator">
                    </div>
                  </div>
                </div>

                  <!-- Question 2 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-02">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-02" aria-expanded="false" aria-controls="question-body-02"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>How you will receive your pay stubs?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-02" class="collapse " role="tabpanel" aria-labelledby="question-heading-02" aria-expanded="true">
                      <div class="mt-4">
                        <p>It's all up to you. You can choose to receive them in a single PDF file, where every pay date lists in a page, or receive a zip file (when opened, every pay date will be displayed in an individual PDF file).</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                   <!-- Question 3 -->
                   <div class="mb-4">
                    <div class="" role="tab" id="question-heading-03">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-03" aria-expanded="false" aria-controls="question-body-03"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>What's the average delivery time?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-03" class="collapse " role="tabpanel" aria-labelledby="question-heading-03" aria-expanded="true">
                      <div class="mt-4">
                        <p>The documents are instantly sent to you by e-mail, once you're done previewing your stub and submitting the order.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 4 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-04">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-04" aria-expanded="false" aria-controls="question-body-04"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>Are pay stubs legal documents?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-04" class="collapse " role="tabpanel" aria-labelledby="question-heading-04" aria-expanded="true">
                      <div class="mt-4">
                        <p>As long as you've entered correct information, the pay stub is considered legal. Yet, sometimes it's required to have them certified (or signed) by the company represented on the stub.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 5 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-05">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-05" aria-expanded="false" aria-controls="question-body-05"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>Can you correct an order after submitting?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-05" class="collapse " role="tabpanel" aria-labelledby="question-heading-05" aria-expanded="true">
                      <div class="mt-4">
                        <p>You can contact us and send us a message with the amendments you need to place, or create another stub and contact us for a refund. In the second case, it must be obvious that a small detail is amended (as a result to a mistake, not an employee name for example), in order to refund your wrong order.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                  <!-- Question 6 -->
                  <div class="mb-4">
                    <div class="" role="tab" id="question-heading-06">
                      <div class="row">
                        <div class="col-12 no-padding faq-head">
                          <a data-toggle="collapse" data-parent="#faq" href="#question-body-06" aria-expanded="false" aria-controls="question-body-06"
                            class="collapsed ">
                            <i class="fa" aria-hidden="true"></i>
                            <h5>Does your information remain secure?</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="question-body-06" class="collapse " role="tabpanel" aria-labelledby="question-heading-06" aria-expanded="true">
                      <div class="mt-4">
                        <p>Without any doubt, it's protected by the SSL/TLS encryption. We use Let's Encrypt SSL to guarantee your information security.</p>
                        <hr class="content-separator">  
                    </div>
                    </div>
                  </div>

                    <!-- Question 7 -->
                    <div class="mb-4">
                        <div class="" role="tab" id="question-heading-07">
                          <div class="row">
                            <div class="col-12 no-padding faq-head">
                              <a data-toggle="collapse" data-parent="#faq" href="#question-body-07" aria-expanded="false" aria-controls="question-body-07"
                                class="collapsed ">
                                <i class="fa" aria-hidden="true"></i>
                                <h5>How about the mode of payment?</h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div id="question-body-07" class="collapse " role="tabpanel" aria-labelledby="question-heading-07" aria-expanded="true">
                          <div class="mt-4">
                            <p>We adopt PayPal as a payment processor. You can either pay using your PayPal account. or choose to "Pay with an online bank account, debit or credit card". The second option allows you to pay using any Credit Card (VISA, MasterCard, Discover, American Express). No need to have a PayPal account if you go for this payment method.</p>
                            <hr class="content-separator">  
                        </div>
                        </div>
                      </div>

                      <!-- Question 8 -->
                    <div class="mb-4">
                        <div class="" role="tab" id="question-heading-08">
                          <div class="row">
                            <div class="col-12 no-padding faq-head">
                              <a data-toggle="collapse" data-parent="#faq" href="#question-body-08" aria-expanded="false" aria-controls="question-body-08"
                                class="collapsed ">
                                <i class="fa" aria-hidden="true"></i>
                                <h5>Any discount for multiple orders?</h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div id="question-body-08" class="collapse " role="tabpanel" aria-labelledby="question-heading-08" aria-expanded="true">
                          <div class="mt-4">
                            <p>Each check stub pay period is considered as an independent order. For example, an order of a single pay period is sold for $7.5, while an order of 2 pay periods costs $15.</p>
                            <hr class="content-separator">  
                        </div>
                        </div>
                      </div>
              
                         <!-- Question 9 -->
                    <div class="mb-4">
                        <div class="" role="tab" id="question-heading-09">
                          <div class="row">
                            <div class="col-12 no-padding faq-head">
                              <a data-toggle="collapse" data-parent="#faq" href="#question-body-09" aria-expanded="false" aria-controls="question-body-09"
                                class="collapsed ">
                                <i class="fa" aria-hidden="true"></i>
                                <h5>Satisfaction guaranteed?</h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div id="question-body-09" class="collapse " role="tabpanel" aria-labelledby="question-heading-09" aria-expanded="true">
                          <div class="mt-4">
                            <p>This service was created to fulfill our customers' detailed needs. If, for any reason, you're not satisfied with the service offered, we can grant you a full refund within 30 days after your order is placed.</p>
                        </div>
                        </div>
                      </div>
      
              </div>
          </div>
        </div>

        <div class="row pb-5">
          <div class="col-lg-10 offset-lg-1 ">
            <hr class="content-separator">
          </div>
          <div class="col-lg-10 offset-lg-1 ">
            <a routerlink="/create-stub" class="primary-btn"> Create Your Pay Stub Now </a>
          </div>
        </div>
  
      </div>
    </section><!-- End Stub-FAQ Section -->
  
  </main><!-- End #main -->
  